import villa1 from '../Assets/villa1.jpg';
import villa2 from '../Assets/villa2.jpg';
import villa3 from '../Assets/villa3.jpg';
import villa4 from '../Assets/villa4.jpg';
import villa5 from '../Assets/villa5.jpg';
import villa6 from '../Assets/villa6.jpg';
import salloni_gray from '../Assets/salloni_gray.jpg';
import salloni_orange from '../Assets/salloni_orange.jpg';
import salloni_yellow from '../Assets/salloni_yellow.jpg';
import salloni2 from '../Assets/salloni2.jpg';
import kuzhina from '../Assets/kuzhina1.jpg';
import dhoma from '../Assets/dhoma.jpg';
import dhoma1 from '../Assets/dhoma1.jpg';
import dhoma2 from '../Assets/dhoma2.jpg';
import t from '../Assets/t.jpg';
import wc from '../Assets/wc.jpg';


const VillasData = [
  {
    id: 1,
    images: [villa1, salloni_gray, salloni2, kuzhina, dhoma, dhoma1, dhoma2, t, wc],
    title: 'Villa 1',
    descriptionKey: 'villas.villa1.description',
  },
  {
    id: 2,
    images: [villa2, salloni_gray, salloni2, kuzhina, dhoma, dhoma1, dhoma2, t, wc],
    title: 'Villa 2',
    descriptionKey: 'villas.villa2.description',
  },
  {
    id: 3,
    images: [villa3, salloni_yellow, salloni2, kuzhina, dhoma, dhoma1, dhoma2, t, wc],
    title: 'Villa 3',
    descriptionKey: 'villas.villa3.description',
  },
  {
    id: 4,
    images: [villa4, salloni_orange, salloni2, kuzhina, dhoma, dhoma1, dhoma2, t, wc],
    title: 'Villa 4',
    descriptionKey: 'villas.villa4.description',
  },
  {
    id: 5,
    images: [villa5, salloni_orange, salloni2, kuzhina, dhoma, dhoma1, dhoma2, t, wc],
    title: 'Villa 5',
    descriptionKey: 'villas.villa5.description',
  },
  {
    id: 6,
    images: [villa6, salloni_yellow, salloni2, kuzhina, dhoma, dhoma1, dhoma2, t, wc],
    title: 'Villa 6',
    descriptionKey: 'villas.villa6.description',
  },
];

export default VillasData;
